<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title">当前积分：{{ integral }}</div>
        <div class="rb1" @click="assistreg">
          <img src="@/assets/add.png" class="icon" />
          <div class="text">协助登记</div>
          <!--<router-link to="/barcounter/check_in_assist_register">
            <div class="text">协助登记</div>
          </router-link>-->
        </div>
      </div>
      <div class="content">
        <div class="items">
          <div class="item">
            <div class="box qq">
              <div class="name">
                <span class="roomcode djqq">登记请求时间</span><span class="roomcode dj">{{ request_time }}</span>
              </div>         
            </div>           
          </div>
          <div class="item">
            <div class="box zz">
              <div class="name tt">
                <span class="roomcode f">{{ name }}</span>
              </div>
              <div class="name">
                <span class="region">{{ mobile }}</span>
              </div>
              <div class="name">
                <span class="region">{{ certificate_type }}</span>
              </div>
              <div class="name">
                <span class="region">入住人数：{{ numpeo }}人</span>
                <span class="region xq" @click="travelerslist">详情</span>
              </div>
            </div>
            <div class="box yy">
              <div class="name">                              
                <div v-if="state === '0'" class="rb2">
                  <div class="text">扫</div>
                </div>
                <div v-if="state === '1'" class="rb2 xie">
                  <div class="text">协</div>
                </div>
              </div>              
            </div>
            <div class="box yy1">
              <div class="name tt">                              
                <img :src="imgstr" class="imgpic" />
              </div>              
            </div>            
          </div>
          <div class="item" @click="roomChoose">
            <div class="box yy1">
              <div class="name">
                <span class="headline h0">房间选择</span>
              </div>          
            </div>  
            <!--<div class="box yy2">
              <div class="name">
                <select class="form_select1" v-model="selectLcItem" @change="selectFn($event)">
                    <option v-for="lcitem in lcitems" :key="lcitem.room_code" :value="lcitem.room_code">{{lcitem.room_name}}</option>
                </select>
              </div>          
            </div> -->     
            <div class="box yy2">
              <div class="name">
                <img src="@/assets/arrow.png" class="arrow" />
                <span class="headline h2">{{ roomname }}室</span>
                <span class="headline h5">{{ floor }}层</span>
                <span class="headline h5">{{ region }}区</span>                
              </div>          
            </div>
          </div> 
          <div class="item" v-if="checkin_type !== '1'">
            <div class="box yy1">
              <div class="name">
                <span class="headline h0">入住时间</span>
              </div>          
            </div>  
            <div class="box zz">
              <div class="name">
                <input type="text" class="datespace" v-model="dateSpace" ref="calendarTigger" placeholder="请选择时间区间" readonly>
              </div>          
            </div>     
            <div class="box yy">
              <div class="name">
                <span class="headline h2">共{{ days }}天</span>
              </div>          
            </div>
          </div>
        </div>
      </div>
      <div class="list_ll list2">
        <div class="ll">
          <div class="button primary mt" @click="next">提交</div>         
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Dialog, Toast } from 'vant';
import qs from 'qs';
import moment from "moment";
import { isEmpty, isTel } from '@/utils';
export default {
  created() {
    let openid = get(this.$route, 'query.openId');
    this.token_id = get(this.$route, 'query.token_id');
    this.room_code = get(this.$route, 'query.room_code');
    this.checkin_type = get(this.$route, 'query.checkin_type');
    this.listIdNum = get(this.$route, 'query.listIdNum');
    //alert('listIdNum:'+this.listIdNum);
    if (openid !== null && openid !== undefined && openid.length !== 0) {
      //alert('openid2:'+openid);
      window.sessionStorage.setItem('openid', openid);
    }

    this.dateSpace = get(this.$route, 'query.dateSpace');
    this.startdate = get(this.$route, 'query.startdate');
    this.enddate = get(this.$route, 'query.enddate');
    let days = get(this.$route, 'query.days');
    if (days !== null && days !== undefined && days.length !== 0) {
      this.days = get(this.$route, 'query.days');
    }
    

    if (this.room_code !== null && this.room_code !== undefined && this.room_code.length !== 0) {
      //alert(this.room_code);
      this.roominfo();
    }

    this.hrefurl = location.href;
    window.sessionStorage.setItem('hrefurl', this.hrefurl);
    
    //this.roomlist();//demo用

    this.query();
    this.querystate();

    this.nowdate = this.getdate();
    this.maxdate = this.computeYmd(this.nowdate,1);
  },
  mounted() {
    this.initCalendar()
  },
  data() {
    return {
      integral: 0,
      token_id: '',
      selectLcItem: '',
      request_time:'',
      days: '0',
      name: '', 
      certificate_code: '',
      mobile: '', 
      state: '', 
      imgstr: '',
      startdate: '',
      enddate: '',
      nowdate: '',
      maxdate: '',
      dateSpace: '',
      room_code: '',
      roomname: '',
      region: '',
      floor: '',
      number: '3',
      listIdNum:'',
      certificate_type:'',
      numpeo:'',
      checkin_type:'',
      tjstate:false,
      cpstate:'1',
      errormsg: '', 
      lcitems: [
        /*{id:101, name: '101'},
        {id:102, name: '102'},
        {id:103, name: '103'}*/ 
      ],
      items: [
        /*{name: '王**先生', mobile: '136****5612', state: '1', imgstr: '/img/nan.png'},
        {name: '罗**女士', mobile: '131****8633', state: '2', imgstr: '/img/nv.png'},
        {name: '李**先生', mobile: '133****8876', state: '2', imgstr: '/img/nan.png'},*/
      ]
    };
  },
  methods: {
    getdate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();

      if (month >= 1 && month <= 9) {
          month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
      }
      var currentdate = year + "-" + month + "-" + strDate;
      return currentdate;
    },
    initCalendar() {
      this.$calendarSwitch({
          element: this.$refs.calendarTigger,
          monthSize: 2,
          minDate: this.nowdate,
          maxDate: this.maxdate,
          onSuccess: (data) => {
            this.dateSpace = data.start_date +'~'+ data.end_date;
            this.startdate = data.start_date;
            this.enddate = data.end_date;

            var a = moment(this.enddate);
            var b = moment(this.startdate);
            var c = a.diff(b, 'days')
            this.days = c;

            console.log(this.dateSpace);
          },
          onError: function(msg) {
              console.log(msg)
          }
      });
    },
    computeYmd(val, n) {// 获取给定日期的 n个月后的日期  给定日期格式如：val = 2019-02-26; n = 多少个月
      let str = val.split('-');
      let d = new Date(str[0], str[1], str[2]);
      // 因为getMonth()获取的月份的值只能在0~11之间所以我们在进行setMonth()之前先给其减一
      d.setMonth((d.getMonth()-1) + n);
      let yy1 = d.getFullYear();
      let mm1 = d.getMonth()+1;
      let dd1 = d.getDate()-1;
      if(dd1 == '00'){
        mm1 = parseInt(mm1)-1;
        let new_date = new Date(yy1,mm1,1);
        dd1 = (new Date(new_date.getTime()-1000*60*60*24)).getDate()
      }
      if (mm1 < 10 ) {
        mm1 = '0' + mm1;
      }
      if (dd1 < 10) {
        dd1 = '0' + dd1;
      }
      return yy1 + '-' + mm1 + '-' + dd1;
    },
    DateDiff(sDate1,  sDate2){    //sDate1和sDate2是2006-12-18格式 
      var  aDate,  oDate1,  oDate2,  iDays 
      aDate  =  sDate1.split("-") 
      oDate1  =  new  Date(aDate[1]  +  '-'  +  aDate[2]  +  '-'  +  aDate[0])    //转换为12-18-2006格式 
      aDate  =  sDate2.split("-") 
      oDate2  =  new  Date(aDate[1]  +  '-'  +  aDate[2]  +  '-'  +  aDate[0]) 
      iDays  =  parseInt(Math.abs(oDate1  -  oDate2)  /  1000  /  60  /  60  /24)    //把相差的毫秒数转换为天数 
      return  iDays 
    },
    selectFn(e) {
      //Toast(this.selectLcItem);
      //Toast(e.target.value);
      // console.log(e)
      // console.log(e.target.selectedIndex) // 选择项的index索引
      // console.log(e.target.value) // 选择项的value
    },
    travelerslist() {
      //this.$router.replace('/barcounter/check_in_travelers_details');     
      this.$router.replace({path:'/barcounter/check_in_travelers_details', query:{
        token_id:this.token_id,
        listIdNum:this.listIdNum,
        dateSpace:this.dateSpace,
        startdate:this.startdate,
        enddate:this.enddate,
        days:this.days,
        room_code:this.room_code
        }});  
        
    },
    assistreg() {    
      this.$router.replace({path:'/barcounter/check_in_assist_register', query:{token_id:this.token_id,listIdNum:this.listIdNum,certificate_code:this.certificate_code}});  
        
    },
    roomChoose() {
      //this.$router.replace('/barcounter/check_in_room_state');   
      this.$router.replace({path:'/barcounter/check_in_room_state', query:{
        token_id:this.token_id,
        listIdNum:this.listIdNum,
        dateSpace:this.dateSpace,
        startdate:this.startdate,
        enddate:this.enddate,
        days:this.days,
        room_code:this.room_code
      }});    
    },
    async roomlist() {
      let { data } = await this.axios.get('/ctid/TldjService/dj_selectroom');
      if (data.flag === '0') {
        //this.lcitems = data.data;  
        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        this.lcitems = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;
      }else{
        Dialog({ message: "房间信息获取失败" });
      }
      
    },
    async roominfo() {
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_selectroomname',
        qs.stringify({
          room_code: this.room_code
        })
      );
      if (data.flag === '0') {
        /*this.roomname = data.data.room_name;
        this.region = data.data.zone_name;
        this.floor = data.data.room_floor;*/  
        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        var testobj = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;  
        this.roomname = testobj.room_name;
        this.region = testobj.zone_name;
        this.floor = testobj.room_floor;      
      }else{
        Dialog({ message: "房间信息获取失败" });
      }
      
    },
    async query() {
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_selectpeo',
        qs.stringify({
          token_id: this.token_id,
          listIdNum:this.listIdNum
        })
      );
      if (data.flag === '0') {
        /*this.name = data.data.customer_name;
        this.mobile = data.data.customer_tel;     
        this.imgstr = data.data.card_img;  
        this.state = data.data.state;  
        this.request_time = data.data.log_date;  
        this.numpeo = data.data.numpeo;
        this.certificate_code = data.data.certificate_code;
        this.certificate_type = data.data.certificate_type;*/

        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        var testobj = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;

        this.name = testobj.customer_name;
        this.mobile = testobj.customer_tel;     
        this.imgstr = testobj.card_img;  
        this.state = testobj.state;  
        this.request_time = testobj.log_date;  
        this.numpeo = testobj.numpeo;
        this.certificate_code = testobj.certificate_code;
        this.certificate_type = testobj.certificate_type;

      }else{
        //Dialog({ message: "用户信息获取失败" });
        this.$router.replace('/barcounter/msgInvalid');
      }
    },
    async querystate() {
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_bpjz',
        qs.stringify({
          tokenid: this.token_id,
          listidnum:this.listIdNum
        })
      );
      if (data.flag === '0') {
        this.cpstate = '0';    
      }else if (data.flag === '2'){
        this.cpstate = '2';       
      }else{
        Toast(data.message);
        this.errormsg = data.message;
        this.cpstate = '1';
      }
      
    },
    async next() {
      if(this.tjstate){
        return false;
      }
      this.tjstate = true;
      if(this.cpstate === '2'){
        Dialog.confirm({
          showCancelButton: false,
          message: "有人需要上传近照！"
        }).then(async () => {
          this.$router.replace({path:'/barcounter/check_in_travelers_details', query:{
            token_id:this.token_id,
            listIdNum:this.listIdNum,
            dateSpace:this.dateSpace,
            startdate:this.startdate,
            enddate:this.enddate,
            days:this.days,
            room_code:this.room_code
          }});
        });        
      }else if(this.cpstate === '1'){
        Toast(this.errormsg);
        this.tjstate = false;
        return;
      }else if(this.cpstate === '0'){
        if (this.room_code === null || this.room_code === undefined || this.room_code.length==0) {
          Toast('请选择房间!');
          this.tjstate = false;
          return;
        }
        //Dialog({ message: this.startdate+','+this.enddate+','+this.selectLcItem+','+this.days });
    
        if (this.checkin_type==='1') {
          //Toast('有人房间!');
          let { data } = await this.axios.post(
            '/ctid/TldjService/dj_insertroom_rz',
            qs.stringify({
              token_id: this.token_id,
              room_code: this.room_code,
              listIdNum:this.listIdNum
            })
          );
          if (data.flag === '0') {
            this.tjstate = false;
            Dialog.confirm({
              showCancelButton: false,
              message: data.message+"，退出操作页面！"
            }).then(async () => {
              window.WeixinJSBridge.invoke('closeWindow',{},function(res){});
            });
          }else{
            this.tjstate = false;
            Dialog({ message: data.message });
          }
        }else{
          if (this.startdate === null || this.startdate === undefined || this.startdate.length==0 || this.enddate === null || this.enddate === undefined || this.enddate.length==0) {
            Toast('请选择入住时间!');
            this.tjstate = false;
            return;
          }
          let { data } = await this.axios.post(
            '/ctid/TldjService/dj_insertroom',
            qs.stringify({
              token_id: this.token_id,
              room_code: this.room_code,
              stime: this.startdate,
              etime: this.enddate,
              numday: this.days,
              listIdNum:this.listIdNum
            })
          );
          if (data.flag === '0') {
            this.tjstate = false;
            Dialog.confirm({
              showCancelButton: false,
              message: data.message+"，退出操作页面！"
            }).then(async () => {
              window.WeixinJSBridge.invoke('closeWindow',{},function(res){});
            });
          }else{
            this.tjstate = false;
            Dialog({ message: data.message });
          }

        }
      }
      
      
    }    
  }
};
</script>

<style lang="less" scoped>

.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 50px;
    color: #fff;
    font-size: 33px;
    float: left;
    margin-top: 10px;
  }
  
  
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 210px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 44.1px;
      height: 44.1px;
      margin: 10px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  
}

.rb2 {
    height: 80px;
    width: 80px;
    overflow: hidden;
    float: right;
    .icon1 {
      width: 50px;
      height: 50px;
      margin: 8px;
      display: block;

    }
  }
.content {
  padding: 0px;
  margin-top: 40px;
}

.xg {
  width: 660px;
  overflow: hidden;
  margin: 0px auto;
  padding: 30px 0px 20px 0px;
}

.items {
  overflow: hidden;
  padding-bottom: 32px;
  min-height: 616px;
  .item {
    margin: 0px 26px;
    padding: 24px 14px 22px;
    overflow: hidden;
    display: flex;
    border-bottom: 2px solid #eee;
  }
  .box {
    flex: 1;
    overflow: hidden;
    flex:0 0 auto;
    &.zz {
      width: 60%;
    }
    &.yy {
      width: 15%;
    }
    &.yy2 {
      width: 75%;     
    }
    &.yy1 {
      width: 25%;
    }
    &.qq {
      width: 100%;
    }
    .name {
      line-height: 50px;
      overflow: hidden;
      font-size: 32px;
      &.tt {
        margin-top: 5px;
      }
      .arrow {
        float: right;
        display: block;
        //margin-left: 10px;
        margin-top: 8px;
        height: 40px;
      }
      .headline {
        font-size: 26px;
        color: rgb(168, 169, 170);  
        vertical-align: middle;
        line-height: 60px;
        &.h0 {
          font-size: 28px;
          color: rgb(0, 0, 0);           
        }   
        &.h1 {
          margin-right: 30px;
        }
        &.h2 {
          float: right;
          margin-right: 10px;
        }
        &.h3 {
          margin-right: 35px;
        }
        &.h5 {
          float: right;
          margin-right: 35px;
        }
        &.h6 {
          float: right;
        }
      }
      .imgpic {
        display: block;
        width: 168px;   
        height: 205px;  
        float: right;
      }
      .datespace {
        width: 100%; 
        height: 60px; 
        line-height: 60px;
        padding-top: 2.5px;
        color: rgb(168, 169, 170);
        font-size: 26px;
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background-color: transparent;
        cursor: pointer;  
        &.bt {
          width: 110px; 
        }          
      }
      .dxicon {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        vertical-align: middle;
        margin-top: -6px;
      }
      .state {
        display: block;
        font-size: 26px;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
        &.xq {
          color: rgb(33, 18, 243);
        }
      }
      .floor {
        display: block;
        float: right;
        font-size: 26px;
        color: rgb(168, 169, 170);
        margin-right: 20px;
      }
      .roomcode {
        margin-right: 80px;
        vertical-align: middle;
        &.f {
          font-size: 28px;
          color: rgb(168, 169, 170);
        }
        &.djqq {
          font-size: 28px;
          margin-right: 20px;
        }
        &.dj {
          font-size: 26px;
        }
      }
      .region {
        font-size: 26px;
        color: rgb(168, 169, 170);
        &.xq {
          color: rgb(50, 105, 160);
          margin-left: 50px;
        }
      }
      .cx {
        height: 76px;
        vertical-align: middle;
      }
      .icon {
        width: 48px;
        height: 48px;
        vertical-align: middle;
      }
      .input {
        flex: 1;
        margin-right: 15px;
        margin-top: 10px;
        vertical-align: middle;
        line-height: 76px;
        float: left;
        border: 0;
        color: #333;
        width: 290px; 
        height: 50px; 
        font-size: 26px;
        &.qy {
          font-size: 30px;
          color: rgb(184, 188, 190);
        }
      }
      ::-webkit-input-placeholder { //WebKit browsers 
        color:rgb(184, 188, 190);
      }
      :-moz-placeholder { //Mozilla Firefox 4 to 18 
        color:rgb(184, 188, 190);
      }
      ::-moz-placeholder { //Mozilla Firefox 19+ 
        color:rgb(184, 188, 190);
      }
      :-ms-input-placeholder { //Internet Explorer 10+ 
        color:rgb(184, 188, 190);
      }
      .rb2 {
        height: 50px;
        width: 50px;
        float: right;
        overflow: hidden;
        background: rgb(130, 189, 217);
        margin-top: 86px;
        margin-right: 30px;
        border-radius: 15px;
        &.xie {
          background: rgb(170, 149, 207);
        }
        .icon {
          width: 30px;
          height: 30px;
          margin: 14px 5px 14px 13px;
          display: block;
          float: left;
        }        
        .text {
          float: left;
          color: #fff;
          font-size: 30px;
          line-height: 50px;
          display: block;
          margin-left: 9px;
        }
      }
      .form_select {
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        /* 右侧添加小箭头的背景图 */
        background: url('/img/xl1.png') 80px center no-repeat;
        background-size: 26px;
        width: 110px;
        height: 76px;
        background-color: transparent;
        float: right;
        font-size: 30px;
        color:  rgb(184, 188, 190);
        line-height: 76px;
        margin-top: 10px;
        vertical-align: middle;
        &.ri {
          margin-right: 20px;
        }
      }
      .form_select1 {
        /* 清除默认边框 */
        border: 0;
        /* 清除默认的箭头样式 */
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        /* 右侧添加小箭头的背景图 */
        background: url('/img/arrow_1.png') 100% center no-repeat;
        background-size: 26px;
        width: 100%;
        height: 60px;
        padding-right: 30px;
        padding-top: 4.5px;
        background-color: transparent;
        font-size: 26px;
        direction: rtl; 
        color: rgb(136, 134, 134);
        line-height: 60px;
        vertical-align: middle;
        float: right;
      }
      select:focus { outline: none; }
      
    }
    .name1 {
      line-height: 50px;
      overflow: hidden;
      font-size: 26px;
      .state {
        display: block;
        float: right;
        color: rgb(236, 120, 25);
        &.zt {
          color: rgb(4, 141, 61);
        }
      }
      .floor {
        display: block;
        float: right;
        color: rgb(184, 188, 190);
        margin-right: 20px;
      }
    }
    .unames {
      line-height: 40px;
      font-size: 28px;
      margin-top: 8px;
      color: #918e8e;
      .pz {
        width: 46px;
        height: 46px;
      }
    }
    .del {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      //margin-top: 18px;
    }
    .edit {
      width: 46px;
      height: 46px;
      display: block;
      float: right;
      margin-right: 20px;
    }
    .view {
      margin-top: 18px;
      width: 198px;
      height: 64px;
      border: 2px solid rgba(0, 29, 255, 1);
      border-radius: 44px;
      text-align: center;
      line-height: 64px;
      color: rgba(0, 29, 255, 1);
      font-size: 28px;
      float: right;
      margin-right: 30px;
    }
  }
}
.empty {
  text-align: center;
  margin-top: 212px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
.list_ll {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  &.list2 {
    margin-top: 15px;
  }
  .ll {
    width: 90%;
    margin: 0px auto;
  }
  
}
</style>
